import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider
} from '@mui/material';
import { CreateNewFolderOutlined, NoteAddOutlined } from '@mui/icons-material';

import { ReactComponent as DocIcon } from 'shared/svg/icon-doc.svg';
import { ReactComponent as TableIcon } from 'shared/svg/icon-table.svg';
import { ReactComponent as PresentationIcon } from 'shared/svg/icon-presentation.svg';
import { ReactComponent as DrawIcon } from 'shared/svg/icon-draw.svg';
import { accessType, supportTypes } from 'shared/constants';
import { request } from 'shared/lib/request';
import { CreateEntityNameDialog, UploadDialog } from 'dialogs';
import {
  fetchDirectoryThunk,
  rootDirectoriesConfig,
  selectDirectory,
  selectRootDirectories,
  selectRootDirectory
} from 'entities/documentDirectory';
import { selectSettings } from 'entities/auth';
import { showSnackbar } from '../Snackbar';
import { saveDataTransferItems } from 'shared/lib/saveFileTree';

export const CreateMenu = ({ anchorEl, onClose, ...props }) => {
  const dispatch = useDispatch();

  const rootDirectories = useSelector(selectRootDirectories);
  const rootDirectory = useSelector(selectRootDirectory);
  const directory = useSelector(selectDirectory);
  const settings = useSelector(selectSettings);

  const [creatableEntity, setCreatableEntity] = React.useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const [uploadLoading, setUploadLoading] = React.useState(false);

  const isDrawSupport = !!settings?.ModuleSettings?.ModulesAccess.find(
    (x) => x === 'Draw'
  );

  const isCurrentDirectoryDocs = () =>
    rootDirectoriesConfig.docs.type === rootDirectory.type;

  const getCurrentDocsDirectoryId = () =>
    isCurrentDirectoryDocs()
      ? directory.Id
      : rootDirectories.find(
          (dir) => dir.type === rootDirectoriesConfig.docs.type
        )?.id ?? null;

  const getCreatableEntityParentId = () => {
    if (!directory) {
      dispatch(
        showSnackbar({
          type: 'warning',
          message: 'Недостаточно прав'
        })
      );
      throw Error('Not enough rights');
    }
    if (
      [accessType.Full, accessType.Write].includes(directory.AccessType) ||
      rootDirectoriesConfig.common.type === rootDirectory.type ||
      rootDirectoriesConfig.sharedToMe.type === rootDirectory.type
    ) {
      return directory.Id;
    }

    return getCurrentDocsDirectoryId();
  };

  const updateDirectory = () => {
    if (
      directory.Id === getCreatableEntityParentId() ||
      [
        rootDirectoriesConfig.common.type,
        rootDirectoriesConfig.docs.type
      ].includes(rootDirectory.type)
    ) {
      dispatch(fetchDirectoryThunk());
    }
  };

  const handleCreateDocument = async (name) => {
    const entity = supportTypes[creatableEntity.documentType];
    const fileParams = {
      DirectoryId: getCreatableEntityParentId(),
      Name: `${name}.${entity.fileType}`,
      MimeType: entity.mimeType
    };

    try {
      await request('v1/Documents/Create', {
        method: 'POST',
        body: fileParams
      });

      setCreatableEntity(null);

      dispatch(
        showSnackbar({
          type: 'success',
          message: `Файл ${fileParams.Name} сохранен!`
        })
      );

      updateDirectory();
    } catch (error) {
      if (error.status === 409) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: `Файл ${fileParams.Name} уже существует!`
          })
        );
      }
      if (error.status === 406) {
        dispatch(
          showSnackbar({
            type: 'warning',
            message: 'Недостаточно прав'
          })
        );
      }
    }
  };

  const handleCreateFolder = async (name) => {
    const folderParams = {
      Name: name,
      Description: `${name} folder`,
      Order: 100,
      Type: 'Default',
      IconId: null,
      RoleIds: [],
      UserIds: [],
      ParentId: getCreatableEntityParentId()
    };

    try {
      await request('v1/DocumentDirectory/AddSubDirectory', {
        method: 'POST',
        body: folderParams
      });

      setCreatableEntity(null);

      dispatch(
        showSnackbar({
          type: 'success',
          message: `Папка ${folderParams.Name} сохранена!`
        })
      );

      updateDirectory();
    } catch (error) {
      if (error.status === 409) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: `Папка ${folderParams.Name} уже существует!`
          })
        );
      }

      if (error.status === 406) {
        dispatch(
          showSnackbar({
            type: 'warning',
            message: 'Недостаточно прав'
          })
        );
      }
    }
  };

  const onFileChangeCapture = async (items) => {
    if (!items.length) return;

    const dirId = getCreatableEntityParentId();

    setUploadLoading(true);

    try {
      await saveDataTransferItems(items, dirId);
      dispatch(
        showSnackbar({
          type: 'success',
          message: `Файлы сохранены`
        })
      );
    } catch (error) {
      let message = 'Ошибка загрузки';
      let type = 'error';

      if (error.status === 406) {
        type = 'warning';
        message = 'Недостаточно прав';
      } else if (error.status === 416) {
        type = 'warning';
        message = 'Превышена квота на хранение';
      }
      dispatch(showSnackbar({ type, message }));
    } finally {
      setUploadLoading(false);
      setUploadDialogOpen(false);
      updateDirectory();
    }
  };

  const handleOptionClick = (option) => {
    switch (option.name) {
      case 'doc':
        setCreatableEntity({ documentType: 'word', type: 'doc' });
        break;
      case 'table':
        setCreatableEntity({ documentType: 'excel', type: 'table' });
        break;
      case 'presentation':
        setCreatableEntity({
          documentType: 'powerPoint',
          type: 'presentation'
        });
        break;
      case 'draw':
        setCreatableEntity({ documentType: 'draw', type: 'draw' });
        break;
      case 'folder':
        setCreatableEntity({ type: 'folder' });
        break;
      case 'download':
        setUploadDialogOpen(true);
        break;
      default:
        break;
    }

    onClose();
  };

  const handleCreateEntity = (name) => {
    switch (creatableEntity.type) {
      case 'doc':
      case 'table':
      case 'presentation':
      case 'draw':
        handleCreateDocument(name);
        break;
      default:
        handleCreateFolder(name);
    }
  };

  return (
    <>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose} {...props}>
        {options
          .filter(
            (x) => x.name !== 'draw' || (x.name === 'draw' && isDrawSupport)
          )
          .map((option, index) =>
            option.name === 'divider' ? (
              <Divider key={index} />
            ) : (
              <MenuItem key={index} onClick={() => handleOptionClick(option)}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            )
          )}
      </Menu>
      <CreateEntityNameDialog
        open={!!creatableEntity}
        type={creatableEntity?.type}
        onClose={() => setCreatableEntity(null)}
        onApply={handleCreateEntity}
      />
      {uploadDialogOpen && (
        <UploadDialog
          loading={uploadLoading}
          onChange={onFileChangeCapture}
          onClose={() => setUploadDialogOpen(false)}
        />
      )}
    </>
  );
};

const options = [
  { name: 'doc', label: 'Документ', icon: <DocIcon /> },
  { name: 'table', label: 'Таблица', icon: <TableIcon /> },
  { name: 'presentation', label: 'Презентация', icon: <PresentationIcon /> },
  { name: 'draw', label: 'Схема', icon: <DrawIcon /> },
  { name: 'divider' },
  { name: 'folder', label: 'Новая папка', icon: <CreateNewFolderOutlined /> },
  { name: 'download', label: 'Загрузить файл', icon: <NoteAddOutlined /> }
];
