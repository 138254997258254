import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { LoadingBox } from '../../shared/components';
import { Close } from '@mui/icons-material';
import * as React from 'react';

const defaultActions = {
  cancel: {
    enabled: true,
    variant: 'text',
    color: 'inherit',
    text: 'Отмена'
  },
  confirm: {
    enabled: true,
    color: 'primary',
    variant: 'contained',
    text: 'Ок'
  }
};

export const ConfirmDialog = ({
  loading,
  title,
  content,
  actions: actionsProps,
  onCancel,
  onConfirm
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const actions = {
    cancel: { ...defaultActions.cancel, ...(actionsProps?.cancel ?? {}) },
    confirm: { ...defaultActions.confirm, ...(actionsProps?.confirm ?? {}) }
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <LoadingBox loading={loading}>
        <DialogTitle
          sx={{ wordBreak: 'break-all', padding: isMobile ? 1.5 : '' }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack sx={{ width: 'calc(100% - 40px)' }}>{title}</Stack>
            <IconButton onClick={onCancel}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          {actions.cancel.enabled && (
            <Button
              variant={actions.cancel.variant}
              color={actions.cancel.color}
              onClick={onCancel}
            >
              {actions.cancel.text}
            </Button>
          )}
          {actions.confirm.enabled && (
            <Button
              variant={actions.confirm.variant}
              color={actions.confirm.color}
              onClick={onConfirm}
            >
              {actions.confirm.text}
            </Button>
          )}
        </DialogActions>
      </LoadingBox>
    </Dialog>
  );
};
