export const isMySchool = process.env.REACT_APP_PROJECT_TYPE === 'MY_SCHOOL';
export const isRosKazna = process.env.REACT_APP_PROJECT_TYPE === 'ROSKAZNA';
export const isR7 = process.env.REACT_APP_PROJECT_TYPE === 'R7';

export const widgets = {
  headerHeight: 80,
  sidebarWidth: 320,
  pageHeaderHeight: 80
};

export const zIndex = {
  loading: 901,
  header: 800,
  toolbar: 700,
  widget: 1101
};

export const supportTypes = {
  pdf: {
    fileType: 'pdf',
    documentType: 'word',
    mimeType: 'application/pdf',
    iconType: 'pdf'
  },
  word: {
    fileType: 'docx',
    documentType: 'word',
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    iconType: 'doc'
  },
  word_old: {
    fileType: 'doc',
    documentType: 'word',
    mimeType: 'application/msword',
    iconType: 'doc'
  },
  rtf: {
    fileType: 'rtf',
    documentType: 'word',
    mimeType: 'application/rtf',
    iconType: 'doc'
  },
  odt: {
    fileType: 'odt',
    documentType: 'word',
    mimeType: 'application/vnd.oasis.opendocument.text',
    iconType: 'doc'
  },
  excel: {
    fileType: 'xlsx',
    documentType: 'cell',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    iconType: 'excel'
  },
  ods: {
    fileType: 'ods',
    documentType: 'cell',
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
    iconType: 'excel'
  },
  excel_old: {
    fileType: 'xls',
    documentType: 'cell',
    mimeType: 'application/vnd.ms-excel',
    iconType: 'excel'
  },
  csv: {
    fileType: 'csv',
    documentType: 'cell',
    mimeType: 'text/csv',
    iconType: 'excel'
  },
  powerPoint: {
    fileType: 'pptx',
    documentType: 'slide',
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    iconType: 'ppt'
  },
  powerPoint_old: {
    fileType: 'ppt',
    documentType: 'slide',
    mimeType: 'application/vnd.ms-powerpoint',
    iconType: 'ppt'
  },
  odp: {
    fileType: 'odp',
    documentType: 'slide',
    mimeType: 'application/vnd.oasis.opendocument.presentation',
    iconType: 'ppt'
  },
  draw: {
    fileType: 'draw',
    mimeType: 'application/x-draw',
    iconType: 'draw'
  },
  visioDraw: {
    fileType: 'vsdx',
    mimeType: 'application/vnd.ms-visio.drawing',
    iconType: 'draw'
  },
  visioView: {
    fileType: 'vsdx',
    mimeType: 'application/vnd.ms-visio.viewer',
    iconType: 'draw'
  },
  text: {
    fileType: 'txt',
    documentType: 'text',
    mimeType: 'text/plain',
    iconType: 'doc'
  },
  imgGif: {
    mimeType: 'image/gif',
    fileType: 'png',
    iconType: 'image'
  },
  imgPng: {
    mimeType: 'image/png',
    fileType: 'png',
    iconType: 'image'
  },
  imgJpeg: {
    mimeType: 'image/jpeg',
    fileType: 'jpeg',
    iconType: 'image'
  },
  imgJpg: {
    mimeType: 'image/jpg',
    fileType: 'jpg',
    iconType: 'image'
  },
  imgSvg: {
    mimeType: 'image/svg',
    iconType: 'image'
  },
  imgSvg2: {
    mimeType: 'image/svg+xml',
    iconType: 'image'
  },
  videoMp4: {
    mimeType: 'video/mp4',
    iconType: 'video'
  },
  audio: {
    mimeType: 'audio/mpeg',
    iconType: 'audio'
  },
  archiveZip: {
    mimeType: 'application/x-zip-compressed',
    iconType: 'zip'
  },
  archiveZip2: {
    mimeType: 'application/zip',
    iconType: 'zip'
  },
  archiveRar: {
    mimeType: 'application/x-rar-compressed',
    iconType: 'zip'
  },
  archive7z: {
    mimeType: 'application/x-compressed',
    iconType: 'zip'
  }
};

export const entityTypes = {
  Unknown: 0,
  File: 1,
  Document: 2,
  Directory: 3,
  Customer: 4,
  User: 5,
  Favorite: 6,
  Event: 7,
  Call: 8,
  Visitor: 9,
  Reception: 10,
  Birthday: 11,
  Meeting: 12,
  Calendar: 14,
  Contact: 15,
  Application: 16,
  UserGroup: 17,
  ClientApplication: 18,
  OfflineDocument: 30,
  EmailFolder: 31,
  EmailMessage: 32,
  EmailAttachment: 33
};

export const accessType = {
  LockCopy: -16384,
  LockPrint: -8192,
  Deny: -4096,
  OnlyUpload: -1024,
  FreeBusy: -64,
  //Deny: -1,
  Read: 0,
  Comment: 1,
  Review: 2, 
  Write: 4,
  Delete: 8,
  FullInternal: 256,
  Full: 512,
  Lock: 4096,
  getTopAccessPriority: function (list) {
    if (!list.length) return this.Read;

    const order = [this.Read, this.Comment, this.Write, this.Full];
    list.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    return list.slice(-1).pop();
  },
  getShareAccessType: function (response) {
    const roleRights = response.RoleAccessRights?.map((x) => x.Type) || [];
    const userRights = response.UserAccessRights?.map((x) => x.Type) || [];
    return this.getTopAccessPriority([
      ...new Set([...roleRights, ...userRights])
    ]);
  }
};

export const accessTypeLabels = {
  [accessType.Deny]: 'Без доступа',
  [accessType.Read]: 'Чтение',
  [accessType.Comment]: 'Комментирование',
  [accessType.Write]: 'Запись',
  [accessType.OnlyUpload]: 'Только загрузка',
  [accessType.Delete]: 'Удаление',
  [accessType.Full]: 'Полный доступ'
};

export const userStatuses = {
  Ban: -2,
  Deleted: -1,
  Disabled: 0,
  Active: 1
};

export const linkLifeTimeType = {
  SingleCall: 0,
  FiveMinutes: 1,
  OneHour: 2,
  Day: 3,
  Week: 4,
  Month: 5,
  Year: 6,
  Unlimited: 10,
  Internal: 11,
  getName: function (type) {
    return Object.entries(this).find((x) => x[1] === type)[0];
  }
};

export const validation = {
  baseMaxLengthInput: 255
};

export const formErrorMessages = {
  required: 'Поле должно быть заполнено',
  number: 'Значение должно быть числом',
  email: 'Некорректный e-mail',
  confirmPassword: 'Пароли не совпадают',
  maxLength: (value) =>
    `Превышен лимит символов. Максимальная длина символов: ${value}`,
  arrayMin: (value) => `Минимальное количество значений: ${value}`,
  arrayMax: (value) => `Максимальное количество значений: ${value}`
};
