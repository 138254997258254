import { request } from 'shared/lib/request';
import { supportTypes } from 'shared/constants';

export const saveDataTransferItems = async (dataTransferItems, rootDirId) => {
  const traverseFileTreePromise = (item, dirId) => {
    return new Promise((resolve) => {
      if (item instanceof File) {
        (async () => {
          await saveEntity(
            dirId,
            item.type ? item : await fixFileType(item),
            true
          );
          resolve(item);
        })();
      } else if (item.isFile) {
        //can don't sync files upload if don't care about folder update after finish
        item.file(async (file) => {
          await saveEntity(
            dirId,
            file.type ? file : await fixFileType(file),
            true
          );
          resolve(file);
        });
      } else if (item.isDirectory) {
        let dirReader = item.createReader();
        dirReader.readEntries(async (entries) => {
          let entriesPromises = [];
          const folderId = await saveEntity(dirId, item, false);
          if (!folderId) resolve();
          for (let entr of entries)
            entriesPromises.push(traverseFileTreePromise(entr, folderId));
          resolve(Promise.all(entriesPromises));
        });
      }
    });
  };

  return new Promise((resolve, reject) => {
    let entriesPromises = [];
    if (dataTransferItems instanceof FileList) {
      [...dataTransferItems].forEach((file) => {
        entriesPromises.push(traverseFileTreePromise(file, rootDirId));
      });
    } else {
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(
            it.getAsEntry ? it.getAsEntry() : it.webkitGetAsEntry(),
            rootDirId
          )
        );
    }
    Promise.all(entriesPromises).then(() => resolve());
  });
};

const fixFileType = async (fileObj) => {
  const fileType = fileObj.name.slice(fileObj.name.lastIndexOf('.') + 1);

  const supportType = Object.values(supportTypes).find(
    (item) => item.fileType === fileType
  );

  //change empty file type to supported type
  if (supportType) {
    fileObj = await new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsArrayBuffer(fileObj);

      reader.onload = () =>
        resolve(
          new File([new Uint8Array(reader.result)], fileObj.name, {
            type: supportType.mimeType
          })
        );
    });
  }
  return fileObj;
};

const saveEntity = async (dirId, item, isFile = true) => {
  const formData = new FormData();
  if (isFile && dirId) {
    formData.append('DirectoryId', dirId);
  } else {
    //directory.AccessType === accessType.OnlyUpload
    const regex = /^\/link\/(.*)$/i;
    const match = window.location.pathname.match(regex);
    if (match && match.length === 2) {
      const uid = match[1];
      formData.append('DirectoryUid', uid);
    }
  }

  if (isFile) {
    formData.append('file', item);

    await request('v1/Documents/Upload', {
      method: 'POST',
      body: formData
    });
  } else {
    //if require to create
    const folderParams = {
      Name: item.name,
      Description: `${item.name} folder`,
      Order: 100,
      Type: 'Default',
      IconId: null,
      RoleIds: [],
      UserIds: [],
      ParentId: dirId
    };
    try {
      const res = await request('v1/DocumentDirectory/AddSubDirectory', {
        method: 'POST',
        body: folderParams
      });
      return res.Id;
    } catch (error) {
      if (error.ErrorCode === 409) {
        const folders = await request(`v1/DocumentDirectory/Get?id=${dirId}`);
        const existingFolder = folders[0]?.Children.find(x =>x.Name === item.name);
        if (existingFolder)
          return existingFolder.Id;
      } else {
        console.log(`ошибка при создания папки ${item.name}`)
      }
      return 0;
    }
  }
};
