import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  Autocomplete,
  TextField,
  IconButton
} from '@mui/material';
import { OpenInNew, FileDownloadOutlined } from '@mui/icons-material';

import { selectSettings } from 'entities/auth';
import {
  selectRootDirectory,
  documentDirectorySearchThunk
} from 'entities/documentDirectory';
import {
  selectDocumentsSearchResults,
  //documentsSearchThunk,
  downloadDocumentThunk,
  setSearchResults
} from 'entities/documents';
import { supportTypes, validation } from 'shared/constants';
import { ReactComponent as FolderIcon } from 'shared/svg/icon-folder.svg';
import { getFileIcon } from 'routing/pages/Drive/columnsConfig';
import { useDebouncedValue } from '../../shared/lib/debounce';

let abortController;

export const DirectorySearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const rootDirectory = useSelector(selectRootDirectory);
  const results = useSelector(selectDocumentsSearchResults);
  const settings = useSelector(selectSettings);

  const [inputValue, setInputValue] = React.useState('');

  const debouncedValue = useDebouncedValue(inputValue);

  const drawUrl = settings.ModuleSettings.ModuleUrls.find(
    (x) => x.Name === 'Draw'
  )?.Url;

  const options = results.map((item, index) => ({
    ...item,
    label: `#${index} ${item.Name}`
  }));

  const handleOpen = (obj) => async () => {
    if (obj.IsFolder) {
      navigate(`${rootDirectory.path}/${obj.Id}`);
    } else {
      const supportedList = Object.values(supportTypes);
      if (!supportedList.find((x) => x.mimeType === obj.MimeType)) return;
      if (obj.MimeType.includes('x-draw'))
        window.open(`${drawUrl}/#7${obj.Id}`, '_blank', '', true);
      else window.open(`/doc.html?id=${obj.Id}`, '_blank', '', true);
    }
  };

  const handleDownload = (e, obj) => {
    e.stopPropagation();
    dispatch(
      downloadDocumentThunk({
        id: obj.Id,
        fileName: obj.Name,
        type: obj.MimeType
      })
    );
  };

  const handleOptionClick = (option) => () => {
    document.activeElement.blur();
    handleOpen(option)();
  };

  const handleChange = (_, value) => {
    document.activeElement.blur();
    navigate(`/search${!value ? '' : `/${value}`}`);
  };

  const handleKeyDown = (event) => {
    if (event.key !== 'Enter') return;

    if (!inputValue) {
      if (window.location.pathname.includes('/search')) {
        navigate('/docs');
      }
      return;
    }

    event.stopPropagation();

    document.activeElement.blur();
    navigate(`/search/${encodeURIComponent(inputValue)}`);
  };

  React.useEffect(() => {
    const search = async () => {
      if (window.location.href.includes('/search')) return;
      if (!debouncedValue) {
        dispatch(setSearchResults([]));
      } else {
        if (abortController) {
          abortController.abort();
        }

        abortController = new AbortController();

        try {
          await dispatch(
            documentDirectorySearchThunk({
              params: new URLSearchParams({
                text: debouncedValue,
                field: 'All'
              }),
              signal: abortController.signal
            })
          ).unwrap();
        } finally {
          abortController = null;
        }
      }
    };

    search();
  }, [dispatch, debouncedValue]);

  React.useEffect(() => {
    setInputValue('');
  }, [location.pathname]);

  return (
    <Autocomplete
      freeSolo
      // clearOnBlur
      disableCloseOnSelect
      forcePopupIcon={false}
      inputValue={inputValue}
      options={options}
      onKeyDownCapture={handleKeyDown}
      onChange={handleChange}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            ...(props?.sx ?? {}),
            px: isMobile ? '6px !important' : ''
          }}
          onClick={handleOptionClick(option)}
        >
          {!option.IsFolder ? (
            getFileIcon(option.MimeType)
          ) : (
            <FolderIcon style={{ fill: 'darkgrey', flexShrink: 0 }} />
          )}
          <Typography noWrap sx={{ ml: 1, mr: 'auto' }}>
            {option.Name}
          </Typography>
          <IconButton>
            <OpenInNew />
          </IconButton>
          {!option.IsFolder && (
            <IconButton onClick={(e) => handleDownload(e, option)}>
              <FileDownloadOutlined />
            </IconButton>
          )}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Поиск"
          inputProps={{
            ...params.inputProps,
            maxLength: validation.baseMaxLengthInput
          }}
        />
      )}
      onInputChange={(_, newValue) => setInputValue(newValue)}
      sx={{
        width: isMobile ? '' : '60%',
        flexGrow: isMobile ? 1 : '',
        mx: isMobile ? 0.5 : ''
      }}
      componentsProps={{
        popper: {
          sx: {
            display: options.length ? '' : 'none',
            width: isMobile ? 'calc(100% - 16px) !important' : ''
          }
        }
      }}
    />
  );
};
