import {
  Folder,
  ArrowCircleRightOutlined,
  GroupOutlined,
  LinkOutlined,
  AddBoxOutlined,
  ShortcutOutlined,
  DriveFileRenameOutlineOutlined,
  ContentCopyOutlined,
  StarOutlineOutlined,
  Star,
  HistoryOutlined,
  Lock,
  LockOpen,
  FileDownloadOutlined,
  //CheckOutlined,
  DeleteOutlined,
  RestoreFromTrashOutlined
} from '@mui/icons-material';

import { rootDirectoriesConfig } from 'entities/documentDirectory';
import { documentTypes } from 'entities/documents';
import { supportTypes, accessType } from 'shared/constants';
import { roleTypes } from 'entities/role/config';

const isAdmin = (viewer) =>
  viewer.Roles.some((role) => role.Type === roleTypes.Admin);

export const config = {
  divider: {
    name: 'divider'
  },
  goToFolder: {
    name: 'goToFolder',
    label: 'Перейти в папку',
    icon: <Folder />
  },
  open: {
    name: 'open',
    label: 'Открыть',
    icon: <ArrowCircleRightOutlined />
  },
  accessSetting: {
    name: 'accessSetting',
    label: 'Настройка доступа',
    icon: <GroupOutlined />
  },
  externalLink: {
    name: 'externalLink',
    label: 'Внешняя ссылка',
    icon: <LinkOutlined />
  },
  copy: {
    name: 'copy',
    label: 'Копировать в...',
    icon: <AddBoxOutlined />
  },
  move: {
    name: 'move',
    label: 'Переместить в...',
    icon: <ShortcutOutlined />
  },
  rename: {
    name: 'rename',
    label: 'Переименовать',
    icon: <DriveFileRenameOutlineOutlined />
  },
  duplicate: {
    name: 'duplicate',
    label: 'Дублировать',
    icon: <ContentCopyOutlined />
  },
  addToFavorites: {
    name: 'addToFavorites',
    label: 'Добавить в избранное',
    icon: <StarOutlineOutlined />
  },
  deleteFromFavorites: {
    name: 'deleteFromFavorites',
    label: 'Удалить из избранного',
    icon: <Star />
  },
  versionHistory: {
    name: 'versionHistory',
    label: 'История версий',
    icon: <HistoryOutlined />
  },
  lock: {
    name: 'lock',
    label: 'Заблокировать',
    icon: <Lock />
  },
  unlock: {
    name: 'unlock',
    label: 'Разблокировать',
    icon: <LockOpen />
  },
  download: {
    name: 'download',
    label: 'Скачать',
    icon: <FileDownloadOutlined />
  },
  downloadAs: {
    name: 'downloadAs',
    label: 'Скачать как...',
    icon: <FileDownloadOutlined />
  },
  delete: {
    name: 'delete',
    label: 'Удалить',
    icon: <DeleteOutlined />
  },
  restore: {
    name: 'restore',
    label: 'Восстановить',
    icon: <RestoreFromTrashOutlined />
  }
};

const hasSupportedMimeType = (type) => {
  const list = [...Object.keys(supportTypes)].map(
    (typeName) => supportTypes[typeName].mimeType
  );

  return list.includes(type);
};

const canDownloadAs = (mimeType) =>
  [
    supportTypes.word.mimeType,
    supportTypes.word_old.mimeType,
    supportTypes.excel.mimeType,
    supportTypes.excel_old.mimeType,
    supportTypes.csv.mimeType,
    supportTypes.powerPoint.mimeType,
    supportTypes.powerPoint_old.mimeType,
    supportTypes.rtf.mimeType,
    supportTypes.text.mimeType
  ].includes(mimeType);

export const getOptions = (type, items, viewer) => {
  const isMultiple = items.length > 1;

  switch (type) {
    case rootDirectoriesConfig.docs.type:
      if (isMultiple) {
        // if (!!items.find((x) => x.IsFolder)) return [];

        return [
          // config.accessSetting,
          // config.copy,
          // config.move,
          // config.divider,
          // config.download,
          config.delete
        ];
      }

      if (items[0]?.IsFolder) {
        return [
          config.open,
          ...(items[0]?.AccessType === accessType.Full
            ? [config.accessSetting]
            : []),
          config.externalLink,
          config.copy,
          config.move,
          config.divider,
          // items[0]?.IsFavorite
          // ? config.deleteFromFavorites
          // : config.addToFavorites,
          config.rename,
          config.download,
          config.divider,
          config.delete
        ];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        ...(items[0]?.AccessType === accessType.Full
          ? [config.accessSetting]
          : []),
        config.externalLink,
        config.copy,
        config.move,
        config.duplicate,
        config.divider,
        items[0]?.IsFavorite
          ? config.deleteFromFavorites
          : config.addToFavorites,
        config.rename,
        config.versionHistory,
        items[0]?.Type === documentTypes.Locked ? config.unlock : config.lock,
        config.download,
        ...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : []),
        config.divider,
        config.delete
      ];

    case rootDirectoriesConfig.sharedToMe.type:
      if (isMultiple) {
        return [
          // config.copy,
          // config.divider,
          // config.download
        ];
      }

      if (items[0]?.IsFolder) {
        return [
          config.open,
          ...(items[0]?.AccessType === accessType.Full
            ? [config.accessSetting]
            : []),
          ...(items[0]?.AccessType === accessType.Full ? [config.rename] : []),
          ...([accessType.Delete, accessType.Full].includes(
            items[0]?.AccessType
          )
            ? [config.divider, config.delete]
            : []),
          config.download
          //config.divider,
          //config.download
        ];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        ...(items[0]?.AccessType === accessType.Full
          ? [config.accessSetting]
          : []),
        ...(items[0]?.AccessType === accessType.Full ? [config.rename] : []),
        //config.accessSetting,
        //config.externalLink,
        //config.copy,
        items[0]?.IsFavorite
          ? config.deleteFromFavorites
          : config.addToFavorites,
        config.versionHistory,
        config.download,
        ...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : []),
        ...([accessType.Delete, accessType.Full, accessType.Write].includes(
          items[0]?.AccessType
        )
          ? [config.divider, config.delete]
          : [])
      ];

    case rootDirectoriesConfig.sharedAccess.type:
      if (isMultiple) {
        return [
          // config.copy,
          // config.divider,
          // config.download
        ];
      }

      if (items[0]?.IsFolder) {
        return [
          config.open,
          ...(items[0]?.AccessType === accessType.Full
            ? [config.accessSetting]
            : []),
          config.divider,
          config.download
        ];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        ...(items[0]?.AccessType === accessType.Full
          ? [config.accessSetting]
          : []),
        //config.accessSetting,
        //config.externalLink,
        //config.copy,
        config.divider,
        items[0]?.IsFavorite
          ? config.deleteFromFavorites
          : config.addToFavorites,
        //config.versionHistory,
        config.divider,
        config.download,
        ...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : [])
      ];

    case rootDirectoriesConfig.rooms.type:
      if (isMultiple) {
        return [
          // config.copy,
          // config.divider,
          // config.download
        ];
      }

      if (items[0]?.IsFolder) {
        return [
          config.open,
          ...(items[0]?.AccessType === accessType.Full
            ? [config.accessSetting]
            : []),
            ...(items[0].Parent.Type !== rootDirectoriesConfig.rooms.type && [accessType.Delete, accessType.Full].includes(
          items[0]?.AccessType
        )
          ? [config.divider, config.delete]
          : []),
          config.download
        ];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        ...(items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.accessSetting]
          : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.copy]
          : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.move]
          : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.rename]
          : []),
        //config.accessSetting,
        //config.externalLink,
        items[0]?.IsFavorite
          ? config.deleteFromFavorites
          : config.addToFavorites,
        config.versionHistory,
        config.download,
        ...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        [accessType.Delete, accessType.Full, accessType.Write].includes(
          items[0]?.AccessType
        )
          ? [config.divider, config.delete]
          : [])
      ];

    case rootDirectoriesConfig.favorites.type:
      if (isMultiple) {
        return [
          // config.copy,
          // config.divider,
          // config.download
        ];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        //config.accessSetting,
        //config.externalLink,
        //config.copy,
        config.divider,
        items[0]?.IsFavorite
          ? config.deleteFromFavorites
          : config.addToFavorites,
        //config.versionHistory,
        config.download,
        ...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : [])
      ];

    case rootDirectoriesConfig.common.type:
      if (isMultiple) {
        return [
          // config.copy,
          // config.divider,
          // config.download
        ];
      }

      if (items[0]?.IsFolder) {
        //console.log(items, viewer);
        return [
          config.open,
          ...(items[0]?.OwnerId === viewer?.Id ||
          items[0]?.AccessType === accessType.Full
            ? [config.accessSetting]
            : []),
          ...((viewer && isAdmin(viewer)) ||
          items[0]?.OwnerId === viewer?.Id ||
          items[0]?.AccessType === accessType.Full
            ? [config.copy]
            : []),
          ...((viewer && isAdmin(viewer)) ||
          items[0]?.OwnerId === viewer?.Id ||
          items[0]?.AccessType === accessType.Full
            ? [config.move]
            : []),
          ...((viewer && isAdmin(viewer)) ||
          items[0]?.OwnerId === viewer?.Id ||
          items[0]?.AccessType === accessType.Full
            ? [config.rename]
            : []),
          ...((viewer && isAdmin(viewer)) ||
          items[0]?.OwnerId === viewer?.Id ||
          items[0]?.AccessType === accessType.Full
            ? [config.divider, config.delete]
            : []),
          config.download
        ];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        ...(items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.accessSetting]
          : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.copy]
          : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.move]
          : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        items[0]?.AccessType === accessType.Full
          ? [config.rename]
          : []),
        //config.accessSetting,
        //config.externalLink,
        items[0]?.IsFavorite
          ? config.deleteFromFavorites
          : config.addToFavorites,
        config.versionHistory,
        config.download,
        ...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : []),
        ...((viewer && isAdmin(viewer)) ||
        items[0]?.OwnerId === viewer?.Id ||
        [accessType.Delete, accessType.Full, accessType.Write].includes(
          items[0]?.AccessType
        )
          ? [config.divider, config.delete]
          : [])
      ];

    case rootDirectoriesConfig.recycleBin.type:
    case rootDirectoriesConfig.globalRecycleBin.type:
      // if (isMultiple) {
      //   return [
      //     config.copy,
      //     config.divider,
      //     config.download
      //   ];
      // }

      if (items[0]?.IsFolder) {
        return [config.open, config.divider, config.restore, config.delete];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        config.divider,
        config.restore,
        config.delete
      ];

    case rootDirectoriesConfig.recent.type:
      if (isMultiple) {
        return [
          // config.copy,
          // config.divider,
          // config.download
        ];
      }

      return [
        ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        items[0]?.IsFavorite
          ? config.deleteFromFavorites
          : config.addToFavorites,
        config.divider,
        config.download,
        ...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : []),
        config.divider,
        config.delete
      ];

    case rootDirectoriesConfig.fileDepot.type:
      if (isMultiple) {
        return [config.delete];
      }

      return [
        // ...(hasSupportedMimeType(items[0]?.MimeType) ? [config.open] : []),
        // items[0]?.IsFavorite
        //   ? config.deleteFromFavorites
        //   : config.addToFavorites,
        //config.divider,
        config.download,
        //...(canDownloadAs(items[0]?.MimeType) ? [config.downloadAs] : []),
        config.divider,
        config.delete
      ];

    default:
      return [];
  }
};
